import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/material/Link";

const items = [
  {
    icon: <PeopleIcon />,
    title: 'EigenLayer AVS - Guaranteed safety',
    description:
      'Soda Layer is designed based on Eigenlayer’s latest EIGEN Staking AVS, which brings unparalleled security to Soda Layer while reducing the trust bootstrapping cost at the beginning of the protocol.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'EVM Compatibility - Liberating Farcaster Social Data Interoperability',
    description:
      'Soda Layer provides developers an EVM-compatible execution environment, allowing them to easily interact with Farcaster social data in smart contracts. Before the Soda Layer, this data was maintained by off-chain Hubs, greatly unleashing the interoperability potential of Farcaster social data.',
  },
  {
    icon: <CardGiftcardIcon />,
    title: 'Traceable Historical data – Farcaster’s Social Data availability',
    description:
      'In SodaLayer, Farcaster social data is managed through a chain data structure, making the data traceable. Combined with the security brought by EigenLayer AVS, the availability of this data is guaranteed. This is not possible in the original Hubs architecture. Therefore, Soda Layer can become Farcaster\'s trusted DA layer.',
  },
  {
    icon: <CardGiftcardIcon />,
    title: 'Token economic model - improving the decentralization of Farcaster Hubs',
    description:
      'We know that operating a Farcaster Hub does not bring any benefits so far, which may reduce its decentralization. Soda Layer designs a token economic model with dynamic release and flywheel effect, so that the EIGEN Staking Operator who honestly maintains AVS can receive rewards in proportion, thus improving the degree of decentralization.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Cross Chain',
  //   description:
  //     '',
  // },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'hsl(220, 30%, 2%)',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '100%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Soda Layer
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Soda Layer provides Web3 builders a classic Blockchain + VM execution layer to build DApps, and can easily access farcaster's social graph data in a VM environment. Before Soda Layer, these data were stored in off-chain Hubs. At the same time, Soda Layer uses the most advanced Eigen Staking AVS as the system consensus module, making full use of EigenLayer's powerful compatibility with Intersubjective digital tasks, giving the protocol unparalleled security.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                  {/*<Link*/}
                  {/*  color="primary"*/}
                  {/*  variant="body2"*/}
                  {/*  fontWeight="bold"*/}
                  {/*  sx={{*/}
                  {/*    display: 'inline-flex',*/}
                  {/*    alignItems: 'center',*/}
                  {/*    pt: 2,*/}
                  {/*    '& > svg': { transition: '0.2s' },*/}
                  {/*    '&:hover > svg': { transform: 'translateX(2px)' },*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <span>Learn more</span>*/}
                  {/*  <ChevronRightRoundedIcon*/}
                  {/*    fontSize="small"*/}
                  {/*    sx={{ mt: '1px', ml: '2px' }}*/}
                  {/*  />*/}
                  {/*</Link>*/}
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
