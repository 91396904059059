import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages

import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import {metaMaskWallet, rainbowWallet, walletConnectWallet, coinbaseWallet, coin98Wallet, okxWallet} from "@rainbow-me/rainbowkit/wallets";
import { publicProvider } from 'wagmi/providers/public';
import {
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets
} from "@rainbow-me/rainbowkit";
import LandingPage from "./LandingPage";


const blastTestnet = {
  id: 168587773,
  name: "Blast testnet",
  network: "blast testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://blast-sepolia.blockpi.network/v1/rpc/public"]
    },
    public: {
      http: ["https://blast-sepolia.blockpi.network/v1/rpc/public"]
    }
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherscan',
      url: 'https://sepolia.blastscan.io/',
    },
    default: {
      name: 'Blastscan',
      url: 'https://sepolia.blastscan.io/',
    },
  },
}

const blast = {
  id: 81457,
  name: "Blast",
  network: "blast",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.blast.io"]
    },
    public: {
      http: ["https://rpc.blast.io"]
    }
  },
  blockExplorers: {
    etherscan: {
      name: 'Blastscan',
      url: 'https://blastscan.io/',
    },
    default: {
      name: 'Blastscan',
      url: 'https://blastscan.io/',
    },
  },
}

const chainsConfig = [
  blast,
]

const { chains, publicClient } = configureChains(chainsConfig, [publicProvider()]);
// const { wallets } = getDefaultWallets({
//   appName: 'Dlever',
//   projectId,
//   chains,
// });

const projectId = '55fd38a2ecbc4ece97a0a418f24e1069';

const connectors = connectorsForWallets([
  {
    groupName: 'SlerfHat',
    wallets: [
      metaMaskWallet({ projectId, chains,
        options: {
          shimDisconnect: true,
        }
      }), //shimDisconnect: true
      walletConnectWallet({projectId, chains}),
      coinbaseWallet({chains}),
      coin98Wallet({projectId, chains}),
      rainbowWallet({projectId, chains}),
      okxWallet({projectId, chains}),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

let rainbowTheme = darkTheme({
  accentColor: '#FDDF47',
  borderRadius: 'large',
  fontStack: 'system',
});

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  // useDocTitle("Depoint | Free your points");

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider showRecentTransactions={true} chains={chains} theme={rainbowTheme}>
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />
            </Routes>
          </Router>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}


export default App;
